// caricamento lingue
const datatableIt = require('init/datatables/datatable.it.json')
const datatableEn = require('init/datatables/datatable.en.json')

// inizializzazione
$.extend(true, $.fn.dataTable.defaults, {
  autoWidth: false,
  lengthMenu: [ 10, 20, 40, 80 ],
  dom: 'ltipr',
  processing: true,
  serverSide: true,
  language: localStorage.getItem('locale') == 'it' ? datatableIt : datatableEn,
  pagingType: 'first_last_numbers', // numbers | simple | simple_numbers | full | full_numbers | first_last_numbers
  stateSaveParams: function(settings, data) {
    var dataCustom = data.custom;
    var customFilters = (dataCustom) ? JSON.parse(data.custom) : {};
    $('.datatable-filter').each(function (index) { customFilters[this.id] = this.value; });
    $('.datatable-filter-datetime').each(function (index) { customFilters[this.id] = this.value; });
    data.custom = customFilters
  },
  stateLoadParams: function(settings, data) {
    var dataCustom = data.custom;
    if (dataCustom) {
      $('.datatable-filter').each(function (index) { this.value = dataCustom[this.id]; });
      $('.datatable-filter-datetime').each(function (index) { if (dataCustom[this.id] !== undefined ) { this.value = dataCustom[this.id]; } else { this.value = ''; } });
    }
  }
})

$.fn.dataTable.Buttons.defaults.dom.button.className = 'btn';