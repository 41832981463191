import Chart from 'chart.js/auto';
import ApplicationController from './application_controller.js'

const donutCenterText = {
  id: 'donutCenterText',
  writeCenterText: function(ctx, x, y, title, text){
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.fillStyle = 'white';
    let width = ctx.measureText(title).width;
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.beginPath();
    ctx.roundRect(x-(width/2), y-(18/2)-10, width, 18, [4]);
    // ctx.fillRect(x-(width/2), y-(18/2)-10, width, 18);
    ctx.stroke();
    ctx.fill();

    ctx.font = "normal 14px Rubik, sans-serif";
    ctx.fillStyle = '#98a6ad';
    ctx.fillText(title, x, y-10);

    ctx.font = "bold 16px Rubik, sans-serif";
    ctx.fillStyle = 'black';
    ctx.fillText(text, x, y+10);
  },
  afterDraw: function(chart) {
    const {ctx, data, options} = chart;
    let plugin = this;

    const x = chart.getDatasetMeta(0).data[0].x;
    const y = chart.getDatasetMeta(0).data[0].y;
    ctx.save();

    if(chart.getActiveElements().length) {
      chart.getActiveElements().forEach((active) => {
        let label = data.labels[active.index];
        let val = data.datasets[active.datasetIndex].data[active.index];
        val = val > 0 ? new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val) : '-';
        plugin.writeCenterText(ctx, x, y, label, `${val} €`);
      });
    }else{ // default
      let sum = data.datasets[0].data.map((e) => parseFloat(e)).reduce((a,b) => a+b);
      sum = parseFloat(sum.toFixed(2)); // JS fix
      sum = sum > 0 ? new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sum) : '-';
      plugin.writeCenterText(ctx, x, y, 'TOTALE', `${sum} €`);
    }
  }
}

const buildChart = function(el, params){
  Chart.overrides.doughnut.cutout = '60%';
  Chart.defaults.font.family = "'Rubik', 'sans-serif";

  if(params.values.length == 0){
    params.values = [-1];
    params.colors = ['#e9ecef'];
    params.labels = ['mancante'];
  }

  let chartdata = {
    labels: params.labels,
    datasets: [] // cambia a seconda
  }
  let chartopts = {
    layout: { padding: 5 },
    plugins: {
      legend: {
        display: !(el.dataset.legend == 'false'),
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
        },
        position: 'bottom'
      },
      tooltip: {
        enabled: params.type != 'doughnut',
        callbacks: {
          label: function(item){
            return new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.raw);
          }
        },
        backgroundColor: 'rgba(248, 249, 250, 0.8)',
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: 2,
        titleFont: {
          size: 12,
          weight: 'bold'
        },
        titleAlign: 'center',
        titleColor: '#343040',
        bodyAlign: 'center',
        bodyColor: '#343040',
        caretSize: 0,
        padding: 10,
        boxPadding: 4
      }
    }
  }

  // override dataset charts
  switch (params.type) {
    case 'doughnut':
    case 'polarArea':
      chartdata.datasets = [{
        data: params.values,
        backgroundColor: params.colors,
        hoverBorderColor: function(el){
          return params.colors[el.index];
        },
        hoverBorderWidth: 2,
        hoverOffset: 10,
        hoverBackgroundColor: function(el){
          return params.colors[el.index];
        }
      }]
    break;
    case 'bar':
      chartdata.datasets = params.values.map((el, i) => ({...el, backgroundColor: params.colors[i]}));
      // chartopts.plugins.legend = false;
    break;
    case 'multi-barline':
      // each dataset
      chartdata.datasets = params.values.map(function(el, i){
        return { type: 'bar', data: el.data, backgroundColor: params.colors[i], label: el.label }
      });
      let linevals = [];
      if(params.values.length == 2){
        // diff
        for(let t=0;t<params.labels.length;t++){
          let diff = params.values[1].data[t] - params.values[0].data[t];
          linevals.push(diff);
        }
      }else{
        // average
        for(let t=0;t<params.labels.length;t++){
          let tsum = 0;
          for(let i=0;i<params.values.length;i++){
            tsum += parseFloat(params.values[i].data[t]);
          }
          linevals.push(tsum/params.values.length);
        }
      }

      console.log(linevals)
      chartdata.datasets.push({
        type: 'line',
        data: linevals,
        cubicInterpolationMode: 'monotone',
        tension: 0.4, 
        borderColor: '#5E5E62',
        label: 'Andamento'
      });
    break;
    // case 'barline-stacked':
    //   chartdata.datasets = [
    //     {
    //       type: 'bar',
    //       label: 'test',
    //       data: [0, 0, 0, 0, 0, 0, 0, 0 , 0, 10000, 0, 0],
    //       backgroundColor: '#ff0000'
    //     },
    //     {
    //       type: 'bar',
    //       label: 'test2',
    //       data: [0, 0, 0, 0, 0, 0, 0, 0 , 0, 15000, 0, 0],
    //       backgroundColor: '#0000ff'
    //     },
    //     { type: 'line', label: 'totale', data: params.values, cubicInterpolationMode: 'monotone', tension: 0.4, backgroundColor: '#98a6ad' }
    //   ]
    //   chartopts.scales = { x: { stacked: true }, y: { stacked: true } };
    // break;
  }

  return new Chart(el, {
    type: params.type,
    data: chartdata,
    options: chartopts,
    plugins: (params.type == 'doughnut' ? [donutCenterText] : [])
  });
};

export default class extends ApplicationController {

  connect(){
    super.connect();

    let controller = this;
    if(this.element.dataset.values){
      // listener for global reinit
      this.element.dataset.filterable && window.addEventListener('filter-chart', function(){
        controller.initChart();
      });
      let rootEl = $(this.element).parents('[data-local-filter]').get(0);
      rootEl && rootEl.addEventListener('local-filter', function(){
        controller.initChart();
      })
      // init
      controller.initChart();
    }
  }

  /* only called by filters */
  updateExpFilters(e){
    let controller = this;
    controller.stimulate('Project#update_exp_filters', e.target)
    .then(() => {
      // reinit charts global/local
      let rootEl = $(controller.element).parents('[data-local-filter]').get(0);
      rootEl ? rootEl.dispatchEvent(new CustomEvent('local-filter')) : window.dispatchEvent(new CustomEvent('filter-chart'));
    });
  }
  /* only called by filters */

  initChart(){
    let data = JSON.parse(this.element.dataset.values);
    let amounts, labels, colors;

    if(data.multiple){
      labels = data.sets[0].map(function(el){ return el.name }); // same for both
      colors = data.colors;
      amounts = [];
      data.sets.forEach(function(arr, i){
        amounts.push({
          data: arr.map(function(el){ return el.amount; }),
          label: data.labels[i]
        });
      });
    }else{
      amounts = data.sets.map(function(el){ return el.amount });
      labels = data.sets.map(function(el){ return el.name });
      colors = data.sets.map(function(el){ return el.color });
    }

    let chart_type = this.element.dataset.chart;

    this.chart?.destroy();
    this.chart = buildChart(this.element, {
      type: chart_type,
      values: amounts,
      labels: labels,
      colors: colors
    });
  }
}