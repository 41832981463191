function initMetisMenu() {
  //metis menu
  $("#side-menu").metisMenu();
}

function initLeftMenuCollapse() {
  $('#vertical-menu-btn').on('click', function (event) {
    event.preventDefault();
    $('body').toggleClass('sidebar-enable');
    if ($(window).width() >= 992) {
      $('body').toggleClass('vertical-collpsed');
    } else {
      $('body').removeClass('vertical-collpsed');
    }
  });
}

function initRightSidebar() {
  // right side-bar toggle
  $('.right-bar-toggle').on('click', function (e) {
      $('body').toggleClass('right-bar-enabled');
  });

  $(document).on('click', 'body', function (e) {
    if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
      return;
    }

    $('body').removeClass('right-bar-enabled');
    return;
  });
}

// function initDropdownMenu() {
//   if (document.getElementById("topnav-menu-content")) {
//     var elements = document.getElementById("topnav-menu-content").getElementsByTagName("a");
//     for (var i = 0, len = elements.length; i < len; i++) {
//       elements[i].onclick = function (elem) {
//         if (elem.target.getAttribute("href") === "#") {
//           elem.target.parentElement.classList.toggle("active");
//           elem.target.nextElementSibling.classList.toggle("show");
//         }
//       }
//     }
//     window.addEventListener("resize", updateMenu);
//   }
// }

// function updateMenu() {
//   var elements = document.getElementById("topnav-menu-content").getElementsByTagName("a");
//   for (var i = 0, len = elements.length; i < len; i++) {
//     if (elements[i].parentElement.getAttribute("class") === "nav-item dropdown active") {
//       elements[i].parentElement.classList.remove("active");
//       elements[i].nextElementSibling.classList.remove("show");
//     }
//   }
// }

function initComponents() {
  window.nestableSerialize = function(data){
    serialized_list = data.map(function(e){
      let obj = {id: $(e.node).data('id')};
      if(e.children){ obj.children = nestableSerialize(e.children); }
      return obj
    });
    return serialized_list;
  }
  $('.colorpicker-palette').spectrum({
    showPaletteOnly: true,
    palette: [
      ['#ff0a53', '#0085fe'],
      ['#44a2d2', '#564ab1', '#777edd', '#ff679b', '#f1556c', '#f1734f', '#f9bc0b', '#0acf97', '#050505', '#2d7bf4']
  ]
  });
  //** FORM VALIDATION **//
  // $('form').parsley();
  //** LOCALES **//
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'it');
    lang = 'it';
  } else {
    lang = localStorage.getItem('locale');
  }
  Parsley.setLocale(lang);
  //** TOGGLE PWD **//
  window.initPwdToggles = function(){
    $('.togglePassword').on('click', function(){
      $(this).toggleClass('mdi-eye mdi-eye-off');
      input = $($(this).data('toggle'));
      if (input.attr('type') == 'password'){
        input.attr('type', 'text');
      }
      else{
        input.attr('type', 'password');
      }
    });
  }
  //** NUMBER INPUT CON VIRGOLA **//
  Inputmask.extendAliases({
    euro: {
      prefix: "€",
      groupSeparator: ".",
      radixPoint: ",",
      alias: "numeric",
      placeholder: "0",
      autoGroup: true,
      digits: 2,
      digitsOptional: false,
      clearMaskOnLostFocus: false,
      autoUnmask: true,
      unmaskAsNumber: true
    }
  });
  $('input.number-input').on('keypress', function(e){
    var charCode = (e.which) ? e.which : e.keyCode;
    has_comma = $(this).val().indexOf(',') > 0; // only one comma allowed
    if(charCode == 44 && has_comma){
      e.preventDefault();
    }else if (charCode > 31 && (charCode != 44 && charCode != 45 && (charCode < 48 || charCode > 57))){
      e.preventDefault();
    }
  });
}

function init () {
  initMetisMenu();
  initLeftMenuCollapse();
  initRightSidebar();
  // initDropdownMenu();
  initComponents();
  initPwdToggles();
}

$(function(){
  init();
});