import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  set_data(event) {
    let form = document.getElementById('stand-form');
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add('was-validated');
    }else{
      event.preventDefault();
      this.stimulate('StandBuilder#set_data', event.target)
      .then(function(result){
        console.log('ok');
      });
    }
  }

  set_brand(event) {
    let form = document.getElementById('stand-form');
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add('was-validated');
    }else{
      event.preventDefault();
      this.stimulate('StandBuilder#set_brand', event.target)
      .then(function(result){
        console.log('ok');
      });
    }
  }

  set_services(event) {
    event.preventDefault();
    this.stimulate('StandBuilder#set_services', event.target)
    .then(function(result){
      $('#service-search').val('');
      console.log('ok');
    });
  }

  remove_service(event) {
    event.preventDefault();
    let el = event.target.parentElement;
    this.stimulate('StandBuilder#remove_service', el)
    .then(function(result){
      console.log('ok');
    });
  }

  // set_category(event) {
  //   this.stimulate('StandBuilder#set_category', event.target)
  //   .then(function(result){
  //     // TODO
  //     console.log('ok');
  //   });
  // }
}