import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  connect(){
    super.connect();

    if(!window.hasInit){
      window.ckIstances = {};
      this.initDraggable();
      this.initComps();
      window.hasInit = true;
    }
  }

  newItem(event) {
    event.preventDefault();

    let target = event.target.closest('button'); // fontawesome nested svg
    let controller = this;

    controller.stimulate('ContentConfig#new_item', target)
    .then(() => {
      controller.initDraggable();
      controller.initComps();
    });
  }

  removeItem(event) {
    event.preventDefault();

    let target = event.target.closest('a'); // fontawesome nested svg
    let controller = this;

    $.ajax({
      url: '/users/swal_translations',
      success: function(res){
        let translations = res.confirm_delete;

        Swal.fire({
          heightAuto: false,
          title: translations.title,
          text: translations.text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'var(--danger)',
          cancelButtonColor: 'var(--muted)',
          confirmButtonText: '<i class="fas fa-trash-alt"></i> ' + translations.button_ok,
          cancelButtonText: '<i class="fas fa-undo-alt"></i> ' + translations.button_cancel
        }).then((result) => {
          if (result.isConfirmed) {
            controller.stimulate('ContentConfig#remove_item', target)
            .then(() => {
              controller.initDraggable();
              controller.initComps();
            });
          }
        });
      }
    });
  }

  ///** UTILS **///

  attachRemoveLink(el, item_id, blob_id) {
    let link = $(el).find('.dz-remove');
    link.on('click', function(){
      $.ajax({
        url: '/content_items/'+item_id+'/remove_image/'+blob_id,
        method: 'POST',
        success: function(data){
          console.log(data);
        }
      });
    });
  };

  initDraggable() {
    if(window.sortable) { window.sortable.destroy(); }

    if(document.getElementsByClassName('draggable').length){
      let controller = this;
      window.sortable = new Sortable(document.getElementById('content-editor'), {
        draggable: '.draggable',
        handle: '.draggable .drag-handle',
        mirror: {
          constrainDimensions: true
        }
      }).on('sortable:stop', function(ev){
        let data = {
          oldIndex: ev.oldIndex,
          newIndex: ev.newIndex
        }
        controller.stimulate('ContentConfig#sort_items', data)
        .then(() => {
          controller.initComps();
          toastr.options.positionClass = "toast-bottom-right";
          toastr.options.timeOut = 1000;
          toastr.success('Salvato')
        });
      });
    }
  }

  initComps() {
    let controller = this;
    /* INIT NEW COMPS */
    // CK
    $('.ck-editable').each(function(i, el){
      var id = el.id;
      let currentData = '';
      // istanza già salvata nella pagina -> salvo valori e ricreo
      if(window.ckIstances[id]){
        currentData = window.ckIstances[id].getData();
        window.ckIstances[id].destroy();
      }

      // init istance
      BalloonEditor.create(el, {
        toolbar: ['heading' , '|', 'bold', 'italic', 'link', 'bulletedList', '|', 'undo', 'redo'],
        table: {
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        }
      }).then(editor => {
        if(currentData == ''){
          // try getting already present value (on init)
          currentData = editor.getData();
        }
        editor.setData(currentData);
        $('#input-for-'+id).val(currentData);

        // force plaintext paste
        // editor.editing.view.document.on('clipboardInput', (evt, data) => {
        //   data.content = editor.data.htmlProcessor.toView(data.dataTransfer.getData('text/plain'));
        // });

        // update fake input for form submission
        editor.model.document.on('change:data', (evt, data) => {
          $('#input-for-'+id).val(editor.getData());
        });

        window.ckIstances[id] = editor;
      }).catch(error => { console.log(error) });
    });

    // IMG DROPZONE
    $('.gallery-dz').each(function(i, el){
      let item_id = $(el).data('id');
      let present_images = JSON.parse($(el).attr('images'));

      if(el.dropzone){ el.dropzone.destroy(); }

      new Dropzone(el, {
        url: '/content_items/'+item_id+'/upload_image',
        acceptedFiles: 'image/*',
        maxFilesize: 1, // 1MB
        addRemoveLinks: true,
        dictRemoveFile: 'Rimuovi file',
        dictDefaultMessage: 'Clicca o trascina qui per caricare un\'immagine',
        init: function(){
          let myDropzone = this;

          present_images.forEach(img => {
            myDropzone.displayExistingFile(img, img.thumb, function(){
              console.log(img);
            }, 'Anonymous');
            // custom destroy action
            let el = $(myDropzone.previewsContainer).children('.dz-file-preview').get(-1);
            controller.attachRemoveLink(el, item_id, img.id);
          });
        },
        success: function(file, res){
          // custom destroy action (delete media) on .dz-remove
          controller.attachRemoveLink(file.previewTemplate, item_id, res.blob_id);
          $(this.previewsContainer).find('.dz-message').hide();
        },
        removedfile: function(file){
          let container = file.previewElement.parentNode;
          if(container){
            container.removeChild(file.previewElement);
            if($(container).children().length == 1){
              $(container).find('.dz-message').show();
            }else{
              $(container).find('.dz-message').hide();
            }
          }
        }
      });
    });

    // DOC DROPZONE
    $('.docs-dz').each(function(i, el){
      let item_id = $(el).data('id');
      let present_docs = JSON.parse($(el).attr('docs'));

      if(el.dropzone){ el.dropzone.destroy(); }

      new Dropzone(el, {
        url: '/content_items/'+item_id+'/upload_doc',
        acceptedFiles: '.pdf,.doc,.docx,.odt',
        addRemoveLinks: true,
        dictRemoveFile: 'Rimuovi file',
        dictDefaultMessage: 'Clicca o trascina qui per caricare un file',
        init: function(){
          let myDropzone = this;

          present_docs.forEach(doc => {
            myDropzone.displayExistingFile(doc, '/attachment-preview.png', function(){
              console.log(doc);
            }, 'Anonymous');
            // custom destroy action
            let el = $(myDropzone.previewsContainer).children('.dz-file-preview').get(-1);
            controller.attachRemoveLink(el, item_id, doc.id);
          });
        },
        success: function(file, res){
          $(file.previewElement).find('.dz-image img').attr('src', '/attachment-preview.png');
          // custom destroy action (delete media) on .dz-remove
          controller.attachRemoveLink(file.previewTemplate, item_id, res.blob_id);
          $(this.previewsContainer).find('.dz-message').hide();
        },
        removedfile: function(file){
          let container = file.previewElement.parentNode;
          if(container){
            container.removeChild(file.previewElement);
            if($(container).children().length == 1){
              $(container).find('.dz-message').show();
            }else{
              $(container).find('.dz-message').hide();
            }
          }
        }
      });
    });
  }
}