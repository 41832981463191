import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  connect(){
    super.connect();

    if(!window.hasInit){
      this.initDraggable();
      window.hasInit = true;
    }
  }

  newItem(event) {
    event.preventDefault();

    let target = event.target.closest('button'); // fontawesome nested svg
    let controller = this;

    controller.stimulate('HighlightConfig#new_item', target)
    .then(() => {
      controller.initDraggable();
    });
  }

  removeItem(event) {
    event.preventDefault();

    let target = event.target.closest('a'); // fontawesome nested svg
    let controller = this;

    $.ajax({
      url: '/users/swal_translations',
      success: function(res){
        let translations = res.confirm_delete;

        Swal.fire({
          heightAuto: false,
          title: translations.title,
          text: translations.text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'var(--danger)',
          cancelButtonColor: 'var(--muted)',
          confirmButtonText: '<i class="fas fa-trash-alt"></i> ' + translations.button_ok,
          cancelButtonText: '<i class="fas fa-undo-alt"></i> ' + translations.button_cancel
        }).then((result) => {
          if (result.isConfirmed) {
            controller.stimulate('HighlightConfig#remove_item', target)
            .then(() => {
              controller.initDraggable();
            });
          }
        });
      }
    });
  }

  ///** UTILS **///

  initDraggable() {
    if(window.sortable) { window.sortable.destroy(); }

    if(document.getElementsByClassName('draggable').length){
      let controller = this;
      window.sortable = new Sortable(document.getElementById('content-editor'), {
        draggable: '.draggable',
        handle: '.draggable .drag-handle',
        mirror: {
          constrainDimensions: true
        }
      }).on('sortable:stop', function(ev){
        let data = {
          oldIndex: ev.oldIndex,
          newIndex: ev.newIndex
        }
        controller.stimulate('HighlightConfig#sort_items', data)
      });
    }
  }
}