import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  connect(){
    super.connect();

    if(!window.hasInit){
      this.initDraggable();
      window.hasInit = true;
    }
  }

  initDraggable() {
    if(window.sortable) { window.sortable.destroy(); }

    if(document.getElementsByClassName('draggable').length){
      let controller = this;
      window.sortable = new Sortable(document.getElementById('sponsortypes-list'), {
        draggable: '.draggable',
        handle: '.draggable .drag-handle',
        mirror: {
          constrainDimensions: true
        }
      }).on('sortable:stop', function(ev){
        let festival = document.getElementById('filterFestival').value
        let data = {
          festivalId: festival,
          oldIndex: ev.oldIndex,
          newIndex: ev.newIndex
        }
        controller.stimulate('SponsorTypes#sort_items', data)
        .then(() => {
          toastr.options.positionClass = "toast-bottom-right";
          toastr.options.timeOut = 1000;
          toastr.success('Salvato')
        });
      });
    }
  }
}