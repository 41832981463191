import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  connect(){
    super.connect();

    if(!window.hasInit){
      window.ckIstances = {};
      this.initDraggable();
      this.initComps();
      window.hasInit = true;
    }
  }

  ///** UTILS **///

  initDraggable() {
    if(window.sortable) { window.sortable.destroy(); }

    if(document.getElementsByClassName('draggable').length){
      let controller = this;
      window.sortable = new Sortable(document.getElementById('contacts'), {
        draggable: '.draggable',
        handle: '.draggable .drag-handle',
        mirror: {
          constrainDimensions: true
        }
      }).on('sortable:stop', function(ev){
        let data = {
          oldIndex: ev.oldIndex,
          newIndex: ev.newIndex
        }
        controller.stimulate('StaffContacts#sort_items', data)
        .then(() => {
          controller.initComps();
          toastr.options.positionClass = "toast-bottom-right";
          toastr.options.timeOut = 1000;
          toastr.success('Salvato')
        });
      });
    }
  }

  initComps() {
    let controller = this;
    /* INIT NEW COMPS */
    // CK
    $('.ck-editable').each(function(i, el){
      var id = el.id;
      let currentData = '';
      // istanza già salvata nella pagina -> salvo valori e ricreo
      if(window.ckIstances[id]){
        currentData = window.ckIstances[id].getData();
        window.ckIstances[id].destroy();
      }

      // init istance
      BalloonEditor.create(el, {
        toolbar: ['heading' , '|', 'bold', 'italic', 'link', 'bulletedList', '|', 'undo', 'redo'],
        table: {
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        }
      }).then(editor => {
        if(currentData == ''){
          // try getting already present value (on init)
          currentData = editor.getData();
        }
        editor.setData(currentData);
        $('#input-for-'+id).val(currentData);

        // force plaintext paste
        // editor.editing.view.document.on('clipboardInput', (evt, data) => {
        //   data.content = editor.data.htmlProcessor.toView(data.dataTransfer.getData('text/plain'));
        // });

        // update fake input for form submission
        editor.model.document.on('change:data', (evt, data) => {
          $('#input-for-'+id).val(editor.getData());
        });

        window.ckIstances[id] = editor;
      }).catch(error => { console.log(error) });
    });
  }
}